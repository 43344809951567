.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Darker background for better focus */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it is above other elements */
}

.modal-content {
  background-color: #fff;
  padding: 25px; /* More padding for better spacing */
  border-radius: 10px; /* Slightly more rounded corners */
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.4); /* Larger shadow for more depth */
  position: relative;
  max-width: 600px; /* Limit the width for better readability */
  width: 90%; /* Responsive width */
}

.close-button {
  position: absolute;
  top: 15px; /* More space from the top */
  right: 15px; /* More space from the right */
  background: none;
  border: none;
  font-size: 20px; /* Larger font size */
  font-weight: bold; /* Bold for better visibility */
  cursor: pointer;
  color: #007bff; /* Blue color */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.close-button:hover {
  color: #0056b3; /* Darker blue on hover */
}

.user-form {
  display: flex;
  flex-direction: column;
}

.user-form .form-group {
  margin-bottom: 15px; /* Space between form groups */
}

.user-form label {
  margin-bottom: 5px;
}

.user-form input, .user-form textarea, .user-form select {
  width: 100%; /* Full width */
  padding: 10px; /* Padding inside inputs */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  box-sizing: border-box; /* Include padding in width calculation */
}

.user-form input:focus, .user-form textarea:focus, .user-form select:focus {
  border-color: #007bff; /* Blue border on focus */
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Blue shadow on focus */
}

.user-form button[type="submit"] {
  background-color: #007bff; /* Blue color */
  color: #fff; /* White text */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.user-form button[type="submit"]:hover {
  background-color: #0056b3; /* Darker blue on hover */
}