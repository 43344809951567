.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  
  .modal-actions button {
    margin: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .modal-actions button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  button.generate-new-qr-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  button.generate-new-qr-button:hover {
    background-color: #0056b3;
  }
  