body{
    margin: 0px;
}
.waitwhiz-logo-pure-white-backg-icon {
    width: 116px;
    position: relative;
    height: 100px;
    object-fit: cover;
}
.comorin-logo-1 {
    width: 60px;
    position: relative;
    height: 60.1px;
    object-fit: cover;
}
.waitwhiz-logo-pure-white-backg-parent {
    align-self: stretch;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.hi-harman {
    align-self: stretch;
    position: relative;
}
.hi-harman-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.frame-child {
    width: 200px;
    position: absolute;
    margin: 0 !important;
    top: calc(50% - 100.5px);
    left: calc(50% - 100.5px);
    border-radius: 50%;
    background-color: #35b82a;
    height: 200px;
    z-index: 0;
}
.check-icon {
    width: 100px;
    position: absolute;
    margin: 0 !important;
    top: calc(50% - 50.5px);
    left: calc(50% - 50px);
    height: 100px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
}
.ellipse-parent {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    height: 201px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}
.span {
    font-family: Inter;
}
.click-here {
    text-decoration: underline;
    color: #377dff;
}
.for-menu-click-here-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    font-family: Inter;
}
.frame-group {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1px 10px;
    gap: 60px;
}
.frame-parent {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 60px;
}
.iphone-14-15-pro-11 {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 100dvh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    box-sizing: border-box;
    text-align: center;
    font-size: 18px;
    color: #000;
    font-family: Inter;
}


.thank-you-container {
    text-align: center;
    padding: 40px 20px;
    max-width: 600px;
    margin: 50px auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .thank-you-title {
    color: #2c3e50;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .thank-you-message {
    color: #666;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .thank-you-icon {
    font-size: 48px;
    color: #27ae60;
    margin-bottom: 20px;
  }