.waitwhiz-logo-pure-white-backg-icon {
    width: 116px;
    position: relative;
    height: 100px;
    object-fit: cover;
}
.comorin-logo-1 {
    width: 60px;
    position: relative;
    height: 60.1px;
    object-fit: cover;
}
.waitwhiz-logo-pure-white-backg-parent {
    align-self: stretch;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.hi-harman {
    align-self: stretch;
    position: relative;
}
.hi-harman-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.frame-child1 {
    width: 200px;
    position: absolute;
    margin: 0 !important;
    top: 0px;
    /* left: 89.5px; */
    border-radius: 50%;
    background-color: #f54749;
    height: 200px;
    z-index: 0;
}
.close-icon {
    width: 100px;
    position: absolute;
    margin: 0 !important;
    top: 50px;
    /* left: 140px; */
    height: 100px;
    z-index: 1;
}
.ellipse-parent {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    height: 201px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}
.call-comorin {
    align-self: stretch;
    position: relative;
    text-decoration: underline;
    color: #377dff;
}
.please-visit-reception-if-stil-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    font-family: Inter;
}
.frame-group1 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1px 10px;
    gap: 60px;
}
.frame-parent1 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 60px;
}
.iphone-14-15-pro-12 {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 100dvh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    box-sizing: border-box;
    text-align: center;
    font-size: 18px;
    color: #000;
    font-family: Inter;
}

