body{
    margin: 0px;
}
.waitwhiz-logo-pure-white-backg-icon {
    width: 116px;
    position: relative;
    height: 100px;
    object-fit: cover;
}
.comorin-logo-1 {
    width: 60px;
    position: relative;
    height: 60.1px;
    object-fit: cover;
}
.waitwhiz-logo-pure-white-backg-parent {
    align-self: stretch;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.hi-harman {
    align-self: stretch;
    position: relative;
}
.hi-harman-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    font-family: Inter;
}
.users-icon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}
.span {
    font-weight: 600;
}
.waiting-no-container {
    flex: 1;
    position: relative;
}
.users-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 15px;
    gap: 15px;
}
.iconclock {
    width: 24px;
    position: relative;
    height: 24px;
}
.estimated-wait-time-container {
    align-self: stretch;
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
}
.frame-container {
    align-self: stretch;
    border-radius: 8px;
    background-color: #e2e2e2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px 17px;
    gap: 25px;
    text-align: left;
}
.span1 {
    font-family: Inter;
}
.click-here {
    text-decoration: underline;
    color: #377dff;
}
.for-menu-click-here-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.frame-group {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1px 10px;
    gap: 60px;
}
.frame-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 60px;
    z-index: 0;
}
.cancel-queue {
    flex: 1;
    position: relative;
    line-height: 100%;
}
.button {
    width: 331px;
    margin: 0 !important;
    position: fixed;
    bottom: 29px;
    left: calc(50%);
    transform: translateX(-50%);
    border-radius: 8px;
    background-color: #e2e2e2;
    border: 1px solid #232323;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding: 15px 14px;
    z-index: 1;
    font-size: 16px;
    color: #232323;
    font-family: Inter;
}
.iphone-14-15-pro-9 {
    width: 100%;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px 15px;
    box-sizing: border-box;
    gap: 48px;
    text-align: center;
    font-size: 18px;
    color: #000;
    font-family: Inter;

    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: hidden; 
}

