.business-settings-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.business-settings-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.business-settings-form {
    display: flex;
    flex-direction: column;
}

.business-settings-form label {
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
}

.business-settings-form input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    margin-top: 5px;
}

.business-settings-form input:disabled {
    background-color: #f0f0f0;
}

.business-settings-form button {
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-btn {
    background-color: #28a745;
    color: white;
}

.save-btn:hover {
    background-color: #218838;
}

.edit-btn {
    background-color: #007bff;
    color: white;
}

.edit-btn:hover {
    background-color: #0069d9;
}