/* Container for the modal content */
.confirm-modal {
    padding: 20px;
    text-align: center; /* Center the content */
    background-color: #fff; /* In case the Modal wrapper doesn't provide background */
    border-radius: 8px; /* Rounded corners if desired */
    max-width: 500px; /* Set a max width */
    margin: 0 auto; /* Center horizontally if needed */
  }
  
  /* Headings and paragraphs (reuse your existing classes) */
  .h1style {
    color: #3b4a54;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
  }
  
  .h2style {
    color: #54656f;
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .reason-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Action button container */
  .actions {
    display: flex;
    justify-content: center; /* Center the action buttons */
    margin-top: 20px; /* Add some spacing above the buttons */
  }
  
  /* Cancel button */
  .cancel-button {
    background: transparent;
    padding: 10px;
    background-color: white;
    color: #007bff;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    border: 1px solid lightgray;
    transition: background-color 0.3s ease;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    outline: none;
    height: 40px;
    min-width: 120px;
  }
  
  .cancel-button:hover {
    background-color: #ff6666;
    color: white;
  }
  
  /* Confirm button */
  .confirm-button {
    background: #007bff;
    color: white;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    border: 1px solid lightgray;
    transition: background-color 0.3s ease;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    outline: none;
    height: 40px;
    min-width: 120px;
  }
  
  .confirm-button:hover {
    background-color: green;
    color: white;
  }
  