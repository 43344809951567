
.number-modal-content {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center the items horizontally */
  justify-content: space-around; /* Distribute space between elements */
  width: 80%; /* Increase the width of the modal */
  max-width: 500px; /* Set a maximum width */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.number-change-button {
  font-size: 2rem; /* Increase the button size */
  padding: 10px; /* Add padding for a larger click area */
  margin: 10px; /* Add space around the buttons */
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
}

.number-input {
  font-size: 1.5rem; /* Increase the input font size */
  width: 60px; /* Set a fixed width for the input */
  text-align: center;
  margin: 20px 0; /* Add space above and below the input */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: #28a745;
    color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #2980b9;
}

.formula-display {
  margin-top: 20px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.formula-display p {
  margin: 5px 0;
}
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}
