.menu-container {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    padding: 25px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .menu-item:hover {
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  
  .menu-item span {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 500;
  }
  
  .menu-item svg {
    color: #4b5563;
  }
  