.confirm-delete-modal {
  padding: 20px;
  text-align: center; /* Center the content */
}

.confirm-delete-modal h2 {
  margin-bottom: 20px; /* Add some spacing below the heading */
}

.form-group {
  margin-bottom: 20px;
  text-align: left; /* Align the input to the left */
}

.reason-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.reason-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #444;
}

.reason-select option {
  color: #000; /* Text color of dropdown options */
}

.actions {
  display: flex;
  justify-content: center; /* Center the action buttons */
  margin-top: 20px; /* Add some spacing above the buttons */
}

.cancel-button {
  background: transparent;
  width: 100%;
  padding: 10px;
  background-color: white;
  color: #007bff;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center; /* Ensure text is centered */
  margin-right: 10px;
  border: 1px solid lightgray; /* Ensure border is visible */ /* Smaller border radius for consistency */
  transition: background-color 0.3s ease;
  text-decoration: none; /* Remove underline */
  font-family: 'Inter', sans-serif;
  outline: none; /* Remove outline */
  height: 40px; /* Ensure buttons have the same height */
  min-width: 120px;
}

.confirm-button {
  background: transparent;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center; /* Ensure text is centered */
  margin-right: 10px;
  border: 1px solid lightgray; /* Ensure border is visible */ /* Smaller border radius for consistency */
  transition: background-color 0.3s ease;
  text-decoration: none; /* Remove underline */
  font-family: 'Inter', sans-serif;
  outline: none; /* Remove outline */
  height: 40px; /* Ensure buttons have the same height */
  min-width: 120px;
}

.delete-button {
  background-color: #f00; /* Red background for delete button */
  border: none; /* Remove border */
  color: #fff; /* White text color */
  cursor: pointer; /* Show pointer cursor on hover */
  padding: 10px 20px; /* Adjust padding for a better appearance */
  border-radius: 4px; /* Add rounded corners */
}

.cancel-button:hover {
  background-color: #ff6666;
  color:white;
}
.delete-button:hover {
  background-color: #ff6666; /* Darker red background on hover */
}

.confirm-button:hover {
  background-color: green;
  color:white;
}

.h1style{
  color: #3b4a54;
    font-size: 20px;
    font-weight: 500;
    text-Align: left;
}

.h2style{
  color: #54656f;
    font-size: 14px;
    text-Align: left;
}


