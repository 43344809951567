.qr-code-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh; /* Full viewport height */
  text-align: center;
  margin: 0;
}

.qr-code-image {
  max-width: 90%; /* Adjust as needed */
  max-height: 90vh; /* Adjust as needed */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
}
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}