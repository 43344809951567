.confirm-delete-modal {
  padding: 20px;
  text-align: center; /* Center the content */
}

.confirm-delete-modal h2 {
  margin-bottom: 20px; /* Add some spacing below the heading */
}

.form-group {
  margin-bottom: 20px;
  text-align: left; /* Align the input to the left */
}



.actions {
  display: flex;
  justify-content: center; /* Center the action buttons */
  margin-top: 20px; /* Add some spacing above the buttons */
}


.delete-button {
  background-color: #f00; /* Red background for delete button */
  border: none; /* Remove border */
  color: #fff; /* White text color */
  cursor: pointer; /* Show pointer cursor on hover */
  padding: 10px 20px; /* Adjust padding for a better appearance */
  border-radius: 4px; /* Add rounded corners */
}


.delete-button:hover {
  background-color: #c00; /* Darker red background on hover */
}