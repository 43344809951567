/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

/* body {
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background-color: #fff;
  position: relative;
  padding: 20px; /* Add padding to the container */
}

.login-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  text-align: center;
  background: white;
  border-bottom: 1px solid #ddd;
}

.login {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 20px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.login h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.btn {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center; /* Ensure text is centered */
  margin-right: 10px;
  border: 1px solid #ccc; /* Ensure border is visible */ /* Smaller border radius for consistency */ /* Set background color to white */
  transition: background-color 0.3s ease;
  text-decoration: none; /* Remove underline */
  font-family: 'Inter', sans-serif;
  outline: none; /* Remove outline */
  height: 40px; /* Ensure buttons have the same height */
  min-width: 120px; /* Ensure buttons have the same minimum width */
}

.btn:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-bottom: 15px;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .login-container {
    padding: 10px; /* Add padding on small screens */
  }

  .login {
    padding: 20px; /* Reduce padding for smaller screens */
    max-width: 100%; /* Ensure it fits within the screen */
    margin: 10px; /* Adjust margin */
  }

  .form-control {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 8px; /* Adjust padding */
  }


}