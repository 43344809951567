
.circle-info {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px auto;
  margin-top: 90px;
}

.customer-tracking-page{
  background-color: #fff;
}

.circle-info p {
  margin: 10px 0;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.circle-info:hover {
  transform: scale(1.05);
}
.delete-message {
  text-align: center;
  font-size: 18px;
  color: red;
  margin-top: 20px;
  background-color: #fff;
}
.cancel-queue-button {
  position: fixed; /* Keep the button at the bottom */
  bottom: 0; /* Position it at the very bottom of the screen */
  left: 0; /* Align it to the left edge of the screen */
  width: 100%; /* Make the button span the full width of the screen */
  padding: 10px 0; /* Adjust the padding to have uniform spacing */
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0; /* Remove border-radius for full-width appearance */
  cursor: pointer;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow above the button */
  text-align: center; /* Center the text inside the button */
}


.waitwhiz-logo {
  width: 100px; /* Increase the width to make it larger */
  height: 100px; /* Maintain aspect ratio */
}

.business-logo {
  width: 100px; /* Increase the width to make it larger */
  height: 100px;/* Maintain aspect ratio */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.celebration-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

/* Top bar styling */
.top-bar {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
}


/* Welcome message styling */
.welcome-message h1 {
  font-size: 1.5rem;
  text-align: center;
  margin: 20px 0;
  color: #333;
}

/* Circle with tick mark */
.circle-container {
  margin: 20px 0;
}

.green-circle {
  width: 150px;
  height: 150px;
  background-color: #00c853; /* Green color similar to the image */
  border-radius: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-circle{
  width: 150px;
  height: 150px;
  background-color: #e45050; /* Green color similar to the image */
  border-radius: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-mark {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 100px;
  color: #000;
}

.tick-mark {
  font-size: 60px;
  color: #fff;
  font-weight: bold;
}
.cross-mark {
  font-size: 60px; /* Adjust size as needed */
  color: #d4c9c9; /* Adjust color as needed */
  font-weight: bold;
}


/* Info box styling */
.additional-info {
  margin: 20px 0;
  width: 80%;
}

.info-icon {
  margin-right: 5px;
  vertical-align: middle;
}

.info-box {
  display: flex;
  justify-content: space-between;
  background-color: #f4f4f4; /* Matches the background color */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.left-info, .right-info {
  display: flex;
  flex-direction: column;
}

p {
  margin: 5px 0;
}

.bold-value {
  font-weight: 600; /* Bold the value */
}
/* Menu link styling */
.menu-link p {
  font-size: 1rem;
  color: #333;
  margin: 10px 0;
  font-weight: bold;
}

.menu-link a {
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
}

/* Footer message styling */
.footer-message p {
  font-size: 1rem;
  color: #333;
  margin-top: 20px;
  font-weight: bold;
}