.business-tables-modal {
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .business-tables-modal h2 {
    margin: 0 0 20px;
    font-size: 24px;
    text-align: center;
  }
  
  .business-tables-modal ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: auto; /* Add scroll if there are many tables */
  }
  
  .business-tables-modal li {
    padding: 10px;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .business-tables-modal li:hover {
    background-color: #f0f0f0; /* Change background on hover */
  }
  
  .business-tables-modal .close-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.2s;
  }
  
  .business-tables-modal .close-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  