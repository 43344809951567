/* General Styles */
/* body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  color: #333;
} */

.bottom-nav {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  color: #999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.nav-button span {
  margin-top: 4px;
  font-size: 12px;
}

.nav-button.selected {
  color: #3f51b5;
  position: relative;
}

.nav-button.selected::before {
  content: '';
  position: absolute;
  top: -5px;
  width: 100%;
  height: 2px;
  background-color: #3f51b5;
  border-radius: 2px;
}

.bottom-nav button svg {
  margin-bottom: 4px;
}


/* Wrapper Container */
.queue-manager {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  height: 100dvh; /* Full viewport height */
  margin: 0 auto; /* Center horizontally */
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Scroll if content overflows */
}


/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.business-image {
  height: 50px;
}

.user-details {
  display: flex;
  align-items: center;
}

.btn {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center; /* Ensure text is centered */
  margin-right: 10px;
  border: 1px solid #ccc; /* Ensure border is visible */ /* Smaller border radius for consistency */
  transition: background-color 0.3s ease;
  text-decoration: none; /* Remove underline */
  font-family: 'Inter', sans-serif;
  outline: none; /* Remove outline */
  height: 40px; /* Ensure buttons have the same height */
  min-width: 120px;
}

.btn:hover {
  background-color: #e2e6ea;
}

.logout-icon {
  display: inline-flex;
  align-items: center;
  cursor: pointer; /* Makes the div appear clickable */
}


.logout-dropdown {
  position: relative; /* Ensure the dropdown is positioned relative to the icon */
}


.logout-dropdown-menu {
  
  top: 100%; /* Position it below the icon */
  right: 100%; /* Align it to the left of the icon */
  margin-right: 10px; /* Optional: Add some spacing */
  background: white; /* Adjust background as needed */
  border: 1px solid #ddd; /* Add a border */
  border-radius: 4px; /* Add rounded corners */
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  z-index: 1000;
}

.dropdown-menu {
  
  top: 100%; /* Position it below the icon */
  right: 100%; /* Align it to the left of the icon */
  margin-right: 10px; /* Optional: Add some spacing */
  background: white; /* Adjust background as needed */
  border: 1px solid #ddd; /* Add a border */
  border-radius: 4px; /* Add rounded corners */
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  z-index: 1000;
}

.logout-dropdown-menu button {
  background: none;
  border: none;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
}



.logout-dropdown-menu button:last-child {
  color: red;
}

.logout-dropdown-item {
  padding: 12px 16px; /* Adjust padding for larger items */
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  
}

.dropdown-item {
  padding: 12px 16px; /* Adjust padding for larger items */
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Add hover effect */
}


.progress-circle {
  display: inline-block;
  margin-right: 10px;
}

.circular-chart {
  display: block;
  margin: 0 auto;
  max-width: 100%; /* Adjust the size as needed */
  max-height: 100%;
  width: 40px; /* Increase size */
  height: 40px; /* Increase size */
  position: relative; /* Ensure the tooltip positions correctly */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */
}

.circular-chart:hover {
  transform: scale(1.1); /* Slightly enlarge the chart on hover */
  
}

.circular-chart:hover::after {
  content: "Click to Edit"; /* Tooltip text */
  position: absolute;
  top: 100%; /* Position below the chart */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  color: white; /* White text color */
  padding: 5px;
  border-radius: 3px;
  font-size: 10px; /* Adjust font size */
  white-space: nowrap; /* Prevent text from wrapping */
}

.circle-bg {
  fill: none;
  stroke: rgba(0, 0, 139, 0.2); /* Dark blue with transparency */
  stroke-width: 4.5; /* Slightly thicker */
}

.circle {
  fill: none;
  stroke-width: 4.5; /* Slightly thicker */
  stroke: black; /* Black color for the progress circle */
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.percentage {
  fill: black; /* Black text color */
  font-family: 'Roboto', sans-serif;
  font-size: 8px; /* Adjust font size for better visibility */
  text-anchor: middle;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.share-qr-button {
  background-color: transparent,
}

.wait-time-column {
  min-width: 90px;
  white-space: nowrap;
}

.call-icon {
  color: #007bff; /* Set the color of the icon to blue */
  margin-left: 12px; /* Keep the margin to ensure spacing between the text and the icon */
}
.queues {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.queue-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.queue-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 10px; */
}
.queue-header.reserved {
  min-height: 43.5px; /* Set specific height */
  width: 80px; /* Set specific width */
  margin: 0; /* Remove any margins */
}
.toggle-icon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
}
.queue-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ddd;
  margin: 5px;
  flex-grow: 1;
  text-align: center;
}

.queue-button.active {
  background-color: #007bff;
  color: #fff;
}

/* Table Styles */
.queue-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px 20px 90px 0px;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.queue-table th, .queue-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.queue-table th {
  background-color: #f4f4f4;
}

.message-box {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  z-index: 1000;
}

.message-box.success {
  background-color: #4CAF50; /* Green for success */
}

.message-box.error {
  background-color: #F44336; /* Red for error */
}
/* Action Buttons */
.action-button {
  margin-right: 5px;
  cursor: pointer;
  background: none;
  border: none;
}

/* Add Button */
.add-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
}


.dropdown-menu {
  
  top: 100%; /* Position it below the icon */
  right: 100%; /* Align it to the left of the icon */
  margin-right: 10px; /* Optional: Add some spacing */
  background: white; /* Adjust background as needed */
  border: 1px solid #ddd; /* Add a border */
  border-radius: 4px; /* Add rounded corners */
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  z-index: 1000;
}

.dropdown-menu button {
  background: none;
  border: none;
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}

.dropdown-menu button:first-child {
  color: green;
}

.dropdown-menu button:nth-child(2) {
  color: black;
}

.dropdown-menu button:last-child {
  color: red;
}

.phone-link {
  color: #007bff; /* Adjust color as needed */
  text-decoration: none;
}

.phone-link:hover {
  text-decoration: underline;
}

.call-details {
  display: flex;
  align-items: center;
  gap: 5px; /* Adjust spacing between elements */
  font-size: 8px;
}

.call-details > div {
  white-space: nowrap; /* Prevent text wrapping */
}

.notification-panel {
  position: fixed; /* Position fixed to keep it centered */
  top: 10%; /* Center vertically */
  display: none; /* Initially hide the panel */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset by half its own size to perfectly center */
  max-width: 90%; /* Adjust width based on screen size */
  max-height: 80%; /* Adjust height based on screen size */
  overflow-y: auto; /* Scroll if content overflows */
  padding: 20px; /* Padding inside the panel */ /* Background color of the panel *//* Optional: adds shadow */
   /* Optional: rounded corners */
  z-index: 9999; /* Ensure it's on top of other elements */
  /* Includes padding in the width/height calculation */
}


#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .queue-manager {
    padding: 10px;
  }

  .queues {
    
    align-items: stretch;
  }

  .queue-button {
    margin: 5px 0;
  }

  .queue-table {
    font-size: 12px; /* Smaller font size for mobile */
  }

  .vacant-tables{
    margin: 20px 0px 20px 0px;
  }

 
}

.toggle-switch {
  width: 30px;
  height: 16px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-switch.active {
  background-color: green;
}

.toggle-switch.inactive {
  background-color: lightgray;
}

.toggle-knob {
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s ease;
}

.toggle-switch.active .toggle-knob {
  left: 15px; /* Move the knob to the right when active */
}

.gold-badge, .silver-badge {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: darkslategray;
  position: relative;
  margin-right: 8px; /* Add some space after the badge */
}

.gold-badge::after, .silver-badge::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
  height: 13px;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.gold-badge::after {
  background-color: #FFF31A; /* Gold star color */
}

.silver-badge::after {
  background-color: #E6E3E3; /* Silver star color */
}

.queue-table tr.highlight-pending {
  background-color: #f4c18d; /* Example highlight color */
}
