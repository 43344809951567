Add your existing styles here
/* .App { */
  /* text-align: center;
} */

.landing-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.description {
  flex: 1;
  padding: 20px;
}

.login-section {
  flex-basis: 400px; /* Increase the width of the login section */
  padding: 20px;
  border-left: 1px solid #ccc;
}

img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  margin-right: 10px;
}

.additional-info, .testimonials {
  margin-top: 40px;
}

blockquote {
  font-style: italic;
  margin: 20px 0;
}

cite {
  display: block;
  text-align: right;
  margin-top: 10px;
}

