.whatsapp-chat-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 400px;
    border-radius: 8px;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #075e54;
    color: white;
    border-radius: 8px 8px 0 0;
  }
  
  .chat-header h3 {
    margin: 0;
  }
  
  .whatsapp-close-button {
    background: none;
    border: none;
    color: #f0f0f0;
    font-size: 30px;
    cursor: pointer;
  }
  
  .chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
    background: #e5ddd5;
  }
  
  .message {
    margin: 8px 0;
    display: flex;
  }
  
  .message.customer {
    justify-content: flex-start;
  }
  
  .message.system {
    justify-content: flex-end;
  }
  
  .message-content {
    max-width: 70%;
    padding: 8px 12px;
    border-radius: 8px;
    position: relative;
  }
  
  .message.customer .message-content {
    background: white;
  }
  
  .message.system .message-content {
    background: #dcf8c6;
  }
  
  .message-content p {
    margin: 0;
    word-wrap: break-word;
  }
  
  .timestamp {
    font-size: 0.7em;
    color: #666;
    display: block;
    text-align: right;
    margin-top: 4px;
  }
  
  .chat-input {
    display: flex;
    padding: 15px;
    background: #f0f0f0;
    border-radius: 0 0 8px 8px;
    gap: 10px;
  }
  
  .chat-input textarea {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 20px;
    resize: none;
    height: 40px;
  }
  
  .chat-input button {
    padding: 8px 16px;
    background: #075e54;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .chat-input button:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }