.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 85%;
    max-width: 500px;
    text-align: center;
    position: relative;
  }
  
  .modal-content h2 {
    margin-bottom: 10px;
  }
  
  .modal-content p {
    margin-bottom: 20px;
  }

  .terms {
    margin-top: 10px; /* Space above the terms */
    text-align: left; /* Aligns content to the left */
  }
  
  .terms-label {
    display: flex; /* Flexbox for horizontal alignment */
    align-items: center; /* Vertically aligns checkbox and text */
    font-size: 10px; /* Ensures text size consistency */
    gap: 0.6rem; /* Space between checkbox and text */
  }
  
  .terms-link {
    color: cornflowerblue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  
  .terms-content {
    font-size: 9px; /* Adjusts the font size to a smaller size */
    line-height: 1.5; /* Adds space between lines for better readability */
    color: #333;
    text-align: justify; /* Sets a dark gray color for better contrast */
  }
  
  
