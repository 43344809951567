.message-container {
    display: flex;
    align-items: center; /* Vertically center items within the container */
    justify-content: center; /* Horizontally center items within the container */
    text-align: center; /* Center text within the container */
    flex-direction: column;
    background-color: #007bff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 300px; /* Adjust the width as needed */
    font-family: Arial, sans-serif;
    box-sizing: border-box; /* Include padding and border in the width calculation */
  }
  
  .message-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .message-image-container {
    margin-right: 10px;
  }
  
  .message-image {
    border-radius: 50%; /* Circular image */
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
  }
  
  .message-title {
    font-weight: bold;
    font-size: 14px;
    color: #333;
  }
  
  .message-body {
    font-size: 13px;
    color: #fff;
    line-height: 1.4;
  }
  
  .message-container:hover {
    background-color: #e6e6e6;
    cursor: pointer;
  }