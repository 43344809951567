.tables-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh; /* Full viewport height */
  /* margin: 0 auto;  Center horizontally */
  
  background-color: #fff;
  
  
  overflow-y: auto; /* Scroll if content overflows */
  }

  .tables-table{
    margin: 20px 20px 90px 10px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table, th, td {
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 12px 15px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
 

  .change-table-btn {
    flex: 0 0 30%;
    border: none;
    
    padding: 6px;
    
    cursor: pointer;
    align-items: center;
    
  }

  .change-icon {
    width: 20px; /* Adjust as needed */
    height: 20px;
    margin: 0px; /* Adjust as needed */
     /* Optional: To add space between the icon and the text, adjust as necessary */
  }

  .change-table-btn i {
    margin-left: 8px; /* Space for the icon */
  }

  .no-wrap {
    white-space: nowrap;
    min-width: 120px;
  }
  
  .table-dropdown {
   
    padding: 5px;
  }

  .table-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .name {
    flex: 0 0 70%; /* Take 70% of the width */
    margin-right: 10px;
    max-width: 70%; /* Optional: Add some space between the name and the button */
  }
  /* button {
    background-color: #007bff;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
   */
  /* button:hover {
    background-color: #0056b3;
  } */
  
  /* button svg {
    margin-right: 5px;
  } */
  