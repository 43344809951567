.waitwhiz-logo-pure-white-backg-icon {
    width: 116px;
    position: relative;
    height: 100px;
    object-fit: cover;
}
.comorin-logo-1 {
    width: 60px;
    position: relative;
    height: 60.1px;
    object-fit: cover;
}
.waitwhiz-logo-pure-white-backg-parent {
    align-self: stretch;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.hi-harman {
    align-self: stretch;
    position: relative;
}
.hi-harman-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.image-2-icon {
    width: 150px;
    position: relative;
    height: 150px;
    object-fit: cover;
}
.image-2-wrapper {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.please-visit-the {
    align-self: stretch;
    position: relative;
    font-weight: 800;
}
.please-visit-the-reception-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
    color: #f54749;
    font-family: Inter;
}
.frame-group {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1px 10px;
    gap: 20px;
}
.frame-parent {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 60px;
    z-index: 0;
}
.cancel-queue {
    flex: 1;
    position: relative;
    line-height: 100%;
}
.button {
    width: 302px;
    margin: 0 !important;
    position: fixed;
    bottom: 29px;
    left: calc(50%);
    transform: translateX(-50%);
    border-radius: 8px;
    background-color: #e2e2e2;
    border: 1px solid #232323;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 14px;
    min-width: 200px;
    max-width: 300px;
    z-index: 1;
    font-size: 16px;
    color: #232323;
}
.iphone-14-15-pro-10 {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 100dvh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px 15px;
    box-sizing: border-box;
    text-align: center;
    font-size: 18px;
    color: #000;
    font-family: Inter;
}
.business-ad-creative {
    display: flex;
    justify-content: center; /* Horizontally centers the image */
    margin-top: 20px; /* Add some space from the content above */
    padding: 10px;
    width: 100%; /* Full width of the container */
    box-sizing: border-box;
    /* Remove align-items: center to avoid vertical centering issues */
}

.ad-creative-img {
    max-width: 100%; /* Ensure the image doesn't overflow horizontally */
    height: auto; /* Maintain the aspect ratio */
    object-fit: contain; /* Scales the image without cropping */
}

