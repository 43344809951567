.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow: hidden;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  font-family: Roboto;
  color: black;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content-history {
  max-height: calc(90vh - 120px);
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-button {
  position: relative;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.close-button::before,
.close-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  background-color: #000; /* Change to your desired color */
  transition: background-color 0.2s ease;
}

.close-button::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-button::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.close-button:hover::before,
.close-button:hover::after {
  background-color: #ff0000; /* Change to your desired hover color */
}

.modal-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  text-align: right;
}

/* Existing visit history styles */
.visit-history {
  max-height: calc(90vh - 200px);
  overflow-y: auto;
  padding: 10px 15px 10px 15px;
  background: #edecec;
}

.visit-row {
  
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 16px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
}

.history-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}

.status-indicator {
  padding: 6px 8px;
  border-radius: 5px;
  font-size: 14px;
  text-transform: capitalize;
}

.status-indicator.entered {
  background-color: #d4edda;
  color: #155724;
}

.status-indicator.active {
  background-color: #fff3cd;
      color: #856404;
}

.status-indicator.inactive {
  background-color: #f8d7da;
      color: #721c24;
}

.visit-content {
  flex-grow: 1;
}

.days-ago {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
}

.customer-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.visit-date {
  font-size: 14px;
  color: #888;
}

/* Scrollbar styling */
.visit-history::-webkit-scrollbar {
  width: 8px;
}

.visit-history::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.visit-history::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.visit-history::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.secondary-button {
  padding: 8px 16px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.secondary-button:hover {
  background-color: #5a6268;
}


 .history-container {
      max-width: 600px;
      margin: 0 auto;
    }

    .history-header {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .history-card {
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 16px;
      background-color: #fff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
    }

    /* Header with separator */
    .history-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: bold;
    }

    .history-card-header + hr {
      border: none;
      border-top: 1px solid #e0e0e0; /* Light gray line separator */
      margin: 8px 0;
    }
    

    /* Card details styling */
    .history-card-details {
      display: flex;
      justify-content: space-between; /* Align items to opposite sides */
      align-items: center;
      gap: 8px;
      font-size: 14px;
    }

    .history-card-details span {
      display: inline-block;
    }

    /* Status styling */
    .history-card-status {
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
    }

    .status-waiting {
      background-color: #fff3cd;
      color: #856404;
    }

    .status-deleted {
      background-color: #f8d7da;
      color: #721c24;
    }

    .status-entered {
      background-color: #d4edda;
      color: #155724;
    }

    /* Rating box */
    .history-card-rating {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      color: #fff; /* Star and font color remains white */
      padding: 6px 12px 6px 0px;
      border-radius: 6px;
      text-align: center;
    }

    /* Dynamic background colors for review box */
    .rating-3 {
      background-color: #ffa500; /* Amber */
    }

    .rating-4 {
      background-color: #28a745; /* Green */
    }

    .rating-5 {
      background-color: #004d00; /* Darker green */
    }

    /* No review case */
    .no-review {
      font-size: 16px;
      font-weight: bold;
     
      text-align: center;
    }

    /* Description styling */
    .history-card-description {
      font-size: 14px;
     
      line-height: 1.4;
    }





    /*new code*/

    .history-container {
      max-width: 600px;
      margin: 0 auto;
    }

    .history-header {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .history-card {
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 16px;
      background-color: #fff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
    }

    /* Header with separator */
    .history-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: bold;
    }

    .history-card-header + hr {
      border: none;
      border-top: 1px solid #e0e0e0; /* Light gray line separator */
      margin: 8px 0;
    }
    
    .second-line{
      display: flex;
      justify-content: left; /* Align items to opposite sides */
      align-items: center;
    }

    /* Card details styling */
    .history-card-details {
      display: flex;
      justify-content: space-between; /* Align items to opposite sides */
      align-items: center;
      gap: 8px;
      font-size: 14px;
      margin-top:8px;
    }

    .history-card-details span {
      display: inline-block;
    }

    /* Status styling */
    .history-card-status {
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
    }

    .status-waiting {
      background-color: #fff3cd;
      color: #856404;
    }

    .status-deleted {
      background-color: #f8d7da;
      color: #721c24;
    }

    .status-entered {
      background-color: #d4edda;
      color: #155724;
    }

    /* Rating box */
    .history-card-rating {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      color: #fff; /* Star and font color remains white */
      padding: 6px 12px;
      border-radius: 6px;
      text-align: center;
    }

    /* Dynamic background colors for review box */
    .rating-3 {
      background-color: #ffa500; /* Amber */
    }

    .rating-4 {
      background-color: #28a745; /* Green */
    }

    .rating-5 {
      background-color: #004d00; /* Darker green */
    }

    /* No review case */
    .no-review {
      font-size: 16px;
      font-weight: 200;
      color: black; /* Gray for '---' */
      text-align: center;
    }

    /* Description styling */
    .history-card-description {
      font-size: 14px;
      color: #6c757d;
      line-height: 1.4;
    }

    .gray-line {
      border: 0.5px solid #edecec;
      margin: 0; /* Adjust margin as needed */
  }

  .visit-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 120px;
    
  }

  .days-ago {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .exact-date-time {
    margin-left: 10px; /* Adjust as needed for spacing */
    font-size: 0.9em; /* Adjust font size if necessary */
    color: #555; /* Adjust color for better visibility */
  }


  /* --- Add this to the bottom of your existing CustomerHistoryModal.css --- */

/* Container for the review section */
.review-container {
  margin-top: 12px; /* Add spacing above reviews */
}

/* Style for the rating badge (stars, numeric rating, etc.) */
.rating-badge {
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
  padding: 3px 6px;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 13px;
}

/* If there's no rating, we display a neutral background */
.no-rating {
  background-color: #999;
}

/* A quick approach to color-code rating 1..5 */
.rating-1 {
  background-color: #d9534f; /* red-ish */
}
.rating-2 {
  background-color: #f0ad4e; /* orange */
}
.rating-3 {
  background-color: #ffa500; /* amber */
}
.rating-4 {
  background-color: #007bff; /* blue or any color */
}
.rating-5 {
  background-color: #28a745; /* green */
}

/* Review text */
.review-text {
  margin-top: 8px;
  font-size: 14px;
  color: #333;
  line-height: 1.4;
}

/* If no text is available, show a fallback */
.no-review {
  font-size: 14px;
  font-style: italic;
  color: #777; 
  margin-top: 8px;
}
