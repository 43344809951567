/* ChatPage.css */

/* Use a clean font like Roboto. 
   In your real app, you can import a Google Font in index.html or here:
   @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
*/

/* html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
} */

/* Container that wraps ChatList on left and ChatWindow on right */
.whatsapp-page-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

/* ======== ChatList Styles ======== */
.chat-list-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #202c33; /* Dark greenish sidebar */
  color: #ffffff;
}

.chat-list-header {
  padding: 16px;
  background-color: #008069; /* WhatsApp top bar color */
  font-size: 18px;
  font-weight: 500;
}

.chat-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 1;
  overflow-y: auto;
}

.chat-list-item {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-bottom: 1px solid #2a3942;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.chat-list-item:hover {
  background-color: #2a3942;
}

.chat-user-id {
  margin: 0 0 4px 0;
  font-weight: 500;
  color: #e9edef;
  font-size: 15px;
}

.chat-message-text {
  margin: 0;
  color: #b1b3b5;
  font-size: 14px;
}

/* ======== ChatWindow Styles ======== */
.chat-window-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  background-color: #eae6df; /* Typical WhatsApp chat background color */
}

.chat-window-header {
  padding: 16px;
  background-color: #008069;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

/* The main scrollable area for messages */
.chat-window-messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #efeae2; /* Slightly lighter background inside chat */
}

/* Each message bubble */
.chat-window-message {
  margin-bottom: 10px;
  padding: 8px 12px;
  background-color: #ffffff;
  border-radius: 8px;
  display: inline-block;
  max-width: 60%;
  word-wrap: break-word;
  color: #111b21;
}

/* Placeholder if no messages exist */
.chat-window-message-placeholder {
  color: #667781;
  font-style: italic;
  margin-top: 10px;
}

/* Input area at the bottom for new messages */
.chat-window-input-container {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #f0f0f0;
}

.chat-window-input {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: none;
  margin-right: 8px;
  outline: none;
  font-size: 14px;
}

.chat-window-send-button {
  background-color: #008069;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out;
}

.chat-window-send-button:hover {
  background-color: #006d58;
}
.selected-chat {
  background-color: #e0f7fa18; /* Light blue background */
  font-weight: bold; /* Bold text */
}